import React from "react";
import Navbar from "../components/Navbar";
import Work from "../components/Work";

function Workpage(){
    return(
        <>
            <Navbar />
            <Work />
        </>
    )
}

export default Workpage