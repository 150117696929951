import React from "react";
import Navbar from "../components/Navbar";
import Collab from "../components/Collab";

function Collabpage(){
    return(
        <>
            <Navbar />
            <Collab />
        </>
    )
}

export default Collabpage