import React from "react";
import Navbar from "../components/Navbar";
import SignUp from "../components/SignUp";
import ComingSoon from "../components/ComingSoon";

function SignUpPage(){
    return(
        <>
            <Navbar />
            <ComingSoon />
        </>
    )
}

export default SignUpPage