import React from "react";
import Navbar from "../components/Navbar";
import About from "../components/About";

function Aboutpage(){
    return(
        <>
            <Navbar />
            <About />
        </>
    )
}

export default Aboutpage