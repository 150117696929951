const preSetsData = [
    {
        id: 1,
        class: 'item5',
        name: "PRE SET FOR RECORDING",
        desc: '',                
        checkout: 'https://buy.stripe.com/aEU16Oa0J6XS9Z63cg'
    }
]

export default preSetsData