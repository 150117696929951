import React from "react";
import Navbar from "../components/Navbar";
import Pricing from "../components/Pricing";

function Pricingpage(){
    return(
        <>
            <Navbar />
            <Pricing />
        </>
    )
}

export default Pricingpage