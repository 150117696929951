import React from "react";
import Navbar from "../components/Navbar";
import Education from "../components/Education";
import ComingSoon from "../components/ComingSoon";

function Educationpage(){
    return(
        <>
            <Navbar />
            <Education />
        </>
    )
}

export default Educationpage