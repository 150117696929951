import React from "react";
import Navbar from "../components/Navbar";
import Reviews from "../components/Reviews";
import ComingSoon from "../components/ComingSoon";

function Reviewspage(){
    return(
        <>
            <Navbar />
            <ComingSoon />
        </>
    )
}

export default Reviewspage